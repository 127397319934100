
export const QueryMessageProviderSuccessText: Record<string, string | null> = {
    //NOTE: Generally speaking we don't need a success message for simple fetches.
    getAdditionalServices: null,
    getCases: null,
    getHybridReservations: null,
    getListings: null,
    getPortalInfo: null,
    getReservationOrderItems: null,
    getReservationRevenue: null,
    getReservationGuests: null,
    getReservations: null,
    getSingleListing: null,
    getSingleReservation: null,
    postTravelInsuranceQuote: null//[[POST AS QUERY]]
};

export const MutationMessageProviderSuccessText: Record<string, string | null> = {
    deleteReservationGuest: 'Guest removed.',
    patchFlightInformation: 'Flight info updated.',
    postCase: 'Case created.',
    postCaseComment: 'Comment added.',
    postReservationOrderItems: null,
    postReservationGuest: 'Guest added.',
    putReservationGuest: 'Guest updated.',
    patchExtendedStay: null,
    patchCheckinRequest: 'Requests updated.',
    postTravelInsuranceConfirm: null,
    patchCheckoutTime: 'Check-out time updated.',
    patchRegistrationStep: 'Registration updated.'
};

export const QueryMessageProviderErrorText: Record<string, string | null> = {
    getAdditionalServices: 'There was a problem while getting available services.',
    getCases: 'There was a problem while getting cases.',
    getHybridReservations: 'There was a problem while getting attached reservations.',
    getListings: 'There was a problem while getting properties.',
    getPortalInfo: 'There was a problem while getting property info.',
    getReservationOrderItems: 'There was a problem while getting reservation items.',
    getReservationRevenue: 'There was a problem while getting transactions.',
    getReservationGuests: 'There was a problem while getting guests.',
    getReservations: 'There was a problem while getting reservations.',
    getSingleListing: 'There was a problem while getting the property.',
    getSingleReservation: 'There was a problem loading your reservation.',
    postTravelInsuranceQuote: 'There was a problem while getting a quote.'//[[POST AS QUERY]]
};

export const MutationMessageProviderErrorText: Record<string, string | null> = {
    deleteReservationGuest: 'There was a problem while removing the guest.',
    patchFlightInformation: 'There was a problem while updating flight information.',
    postCase: 'There was a problem while creating the case.',
    postCaseComment: 'There was a problem while adding the comment.',
    postReservationOrderItems: 'There was a problem while adding the service.',
    postReservationGuest: 'There was a problem while adding the guest.',
    putReservationGuest: 'There was a problem while updating the guest.',
    patchExtendedStay: 'There was a problem while extending the stay.',
    patchCheckinRequest: 'There was a problem while updating check-in requests.',
    postTravelInsuranceConfirm: 'There was a problem while confirming the policy.',
    patchRegistrationStep: 'There was a problem while updating registration.',
    postReview: 'There was a problem while adding the review.',
    putReview: 'There was a problem while updating the review.'
};
